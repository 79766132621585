import { globalHistory } from "@reach/router";

const BASE_HOSTNAME = globalHistory.location.hostname || '';

export const getHostname = () => (BASE_HOSTNAME.indexOf('www.') && BASE_HOSTNAME) || BASE_HOSTNAME.replace('www.', '');

export const getCenterName = () => {
  if (getHostname() === 'localhost') {
    return 'caliceo';
  }
  return getHostname().split('.')[0];
};
