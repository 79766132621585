import { useStaticQuery, graphql } from 'gatsby';

const useGetCenters = () => {
  const { caliceo } = useStaticQuery(graphql`
    {
      caliceo {
        nodeQuery(filter: {conditions: [{field: "type", value: "centre"}]}) {
          entities {
            ... on caliceo_NodeCentre {
              title
              nid
              entityUrl {
                path
              }
              fieldCentreid
              fieldDomainAccess {
                entity {
                  entityId
                }
              }
              fieldAccrocheImage {
                entity {
                  ... on caliceo_MediaImage {
                    fieldMediaImage {
                      title
                      url
                    }
                  }
                }
              }
              fieldMetatagSurmesure {
                entity {
                  ...on caliceo_ParagraphMetatagSurmesure {
                    fieldMetatagTitre
                    fieldMetatagCentre
                    fieldMetatagDescription
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const centers = caliceo.nodeQuery.entities.map((entity) => ({
    name: entity.title,
    nid: entity.nid,
    centerId: entity.fieldCentreid,
    domainAccess: entity.fieldDomainAccess[0].entity?.entityId,
    imageUrl: entity.fieldAccrocheImage.entity.fieldMediaImage.url,
    meta: {
      ...entity.fieldMetatagSurmesure
    }
  }));

  return centers;
};

export default useGetCenters;
