import { createSelector } from 'reselect';

export const selectCenters = (state) => state.centersReducer.centers;

export const selectCentersExist = createSelector(
  selectCenters,
  (centers) => centers.length > 0,
);

export const selectActiveCenterId = (state) => state.centersReducer.activeCenterId;

export const selectActiveCenter = createSelector(
  [selectCenters, selectActiveCenterId],
  (centers, activeCenterId) => centers.find((center) => center.centerId === activeCenterId),
);
