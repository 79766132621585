import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useSpring, animated as a } from 'react-spring';
import { getCookie } from 'api';
import Cookies from 'universal-cookie';
import CaliceoButton from 'components/buttons/CaliceoButton';
import CaliceoLink from 'components/buttons/CaliceoLink';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import forwardToCenter from 'utils/forwardToCenter';
import { getCenterName, getHostname } from 'utils/location';

import CaliceoLogo from 'components/icon-components/CaliceoMenuLogo';
import GPS from 'components/icon-components/GPS';
import GiftIcon from 'components/icon-components/Cadeau';
import BonIcon from 'components/icon-components/BonCadeau';
import CartIcon from 'components/icon-components/Cart';

import RightArrowIcon from 'images/icons/right-arrow.svg';

import IGWhite from 'images/icons/RS/Instagram.svg';
import FBWhite from 'images/icons/RS/FB.svg';
import LinkedinWhite from 'images/icons/RS/linkedin.svg';
import useGetCenters from 'hooks/use-get-centers';

import userIsLoggedIn from 'utils/checkLoggedIn';
import { centerCookieIds } from 'utils/constants';

const cookies = new Cookies();

const DesktopNavbar = (props) => {
  const {
    isScrollOnTop,
    determineIconFill,
    showNavigationMenu,
    setShowNavigationMenu,
    mainMenuContent,
    selectedCenter,
    logout,
    cartData,
  } = props;

  const [nbProduct, setNbProduct] = useState(0);

  useEffect(() => {
    let nb = 0;
    for (let i = 0; i < cartData.Products.length; i++) {
      nb += cartData.Products[i].quantity;
    }
    setNbProduct(nb);
  }, [cartData]);

  const all_centers = useGetCenters();

  const tabStyle = useSpring({
    height: showNavigationMenu ? '100vh' : '0vh',
    opacity: showNavigationMenu ? 1 : 0,
    config: { mass: 1, tension: 400, friction: 30 },
    zIndex: showNavigationMenu ? 100 : -100,
  });

  const navContentAnimations = useSpring({
    opacity: showNavigationMenu ? 1 : 0,
    display: showNavigationMenu ? 'block' : 'none',
  });

  const centersMenu = (
    <Menu>
      {all_centers.map((center) => {
        // console.log(center.centerId);
        if (center.centerId !== 'centre-paris') {
          return (
            <Menu.Item
              key={center.centerId}
              onClick={() => {
                getCookie.get(`?cid=${centerCookieIds[center.centerId].cid}`).then((res) => {
                  cookies.set(
                    `${centerCookieIds[center.centerId].cookieName}`,
                    res.data.responseData.userContent,
                    { path: '/' },
                  );
                  forwardToCenter(all_centers, center.centerId, window.location.hostname);
                });
              }}
            >
              {center.name}
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu>
  );

  const handleUserLogout = () => {
    const selectedCenterId = selectedCenter?.centerId;
    logout(selectedCenterId, cookies.get(centerCookieIds[selectedCenterId].cookieName));
  };
  const hostname = window.location.host;
  const hostnameArray = hostname.split('.');
  // console.log(selectedCenter);
  let groupPath = `https://${hostname}`;
  if ((hostnameArray[0] != 'caliceo') && (hostnameArray[0] != 'www')) {
    hostnameArray.shift();
    groupPath = `https://${hostnameArray.join('.')}`;
  }

  return (
    <header className={`site-header ${isScrollOnTop ? '' : 'site-header--scrolled'}`}>
      <nav className="site-nav">
        <div className="container site-nav-container">
          <div className="nav-content">
            {(document.location.pathname === '/')
              ? (
                <h1>
                  <a className="nav-brand" href={groupPath} style={{ color: determineIconFill() }}>
                    <CaliceoLogo
                      fill={determineIconFill()}
                      selectedCenter={selectedCenter}
                    />
                  </a>
                  {(selectedCenter?.name != 'home-groupe')
                    ? (
                      <Dropdown className="nav-center" overlay={centersMenu} trigger={['click']}>
                        <button type="button" className="dropdown-trigger--btn" onClick={(e) => e.preventDefault()}>
                          <div className="cont_nav_center" style={{ border: `1px solid ${determineIconFill()}` }}>
                            <GPS fill={determineIconFill()} />
                            <p style={{ color: determineIconFill() }}>{selectedCenter?.name}</p>
                            <DownOutlined style={{ color: determineIconFill() }} />
                          </div>
                        </button>
                      </Dropdown>
                    )
                    : ''}
                </h1>
              )
              : (
                <p>
                  <a className="nav-brand" href={groupPath} style={{ color: determineIconFill() }}>
                    <CaliceoLogo
                      fill={determineIconFill()}
                      selectedCenter={selectedCenter}
                    />
                  </a>
                  {(selectedCenter?.name != 'home-groupe')
                    ? (
                      <Dropdown className="nav-center" overlay={centersMenu} trigger={['click']}>
                        <button type="button" className="dropdown-trigger--btn" onClick={(e) => e.preventDefault()}>
                          <div className="cont_nav_center" style={{ border: `1px solid ${determineIconFill()}` }}>
                            <GPS fill={determineIconFill()} />
                            <p style={{ color: determineIconFill() }}>{selectedCenter?.name}</p>
                            <DownOutlined style={{ color: determineIconFill() }} />
                          </div>
                        </button>
                      </Dropdown>
                    )
                    : ''}
                </p>
              )}
            <div className="nav-links--wrapper">

              {(selectedCenter?.name != 'home-groupe')
                ? (
                  <Link className="navbar-link" to="/offers" style={{ color: determineIconFill() }}>
                    <GiftIcon fill={determineIconFill()} />
                    Offrir un cadeau
                  </Link>
                )
                : ''}
              <Link className="navbar-link" to="/utiliser-un-bon-cadeau" style={{ color: determineIconFill() }}>
                <BonIcon fill={determineIconFill()} />
                utiliser un bon cadeau
              </Link>
              <Link to="/node/2295" className="pro-area--btn">Espace pro</Link>
              <Link
                to="/mon-compte"
                className="my-area--btn"
              >
                Mon compte
              </Link>
              {(selectedCenter.domainAccess !== 'caliceo_epfactory_com' && userIsLoggedIn(selectedCenter?.centerId)) && (
                <CaliceoButton
                  title="Déconnexion"
                  primaryButton={false}
                  onClick={handleUserLogout}
                  classNames="logout-button"
                  showIcon={false}
                />
              )}
              <Link
                className="navbar-link"
                to="/panier"
                style={{ color: determineIconFill() }}
              >
                {cartData?.Products.length > 0 ? (
                  <div className="nb_panier">{nbProduct}</div>
                ) : null }

                <CartIcon fill={determineIconFill()} />
                Mon panier
              </Link>
              <button
                className={`navbar-button ${!showNavigationMenu ? '' : 'navbar-open'}`}
                type="button"
                onClick={() => setShowNavigationMenu(!showNavigationMenu)}
                aria-label="Toggle navigation"
              >
                <div className="line line-top" style={{ background: determineIconFill() }} />
                <div className="line line-middle" style={{ background: determineIconFill() }} />
                <div className="line line-bottom" style={{ background: determineIconFill() }} />
              </button>
            </div>
          </div>
        </div>
        <a.div style={tabStyle} className="nav-dropdown-menu--wrapper">
          <a.div className="container" style={navContentAnimations}>
            <div className="mx-3 row navbar-centers--row">
              <div className="col-12 col-lg-8">
                {mainMenuContent && (
                  <div className="navbar-centers--wrapper">
                    {mainMenuContent.fieldMenuMea.map(({ entity }) => (
                      <div className="navbar-center" key={entity.fieldAccrocheTitre.processed}>
                        <div className="navbar-center--image--wrapper">
                          <img
                            src={entity.fieldAccrocheImage?.entity.fieldMediaImage.derivative.url}
                            alt={entity.fieldAccrocheTitre?.processed}
                            className="navbar-center--image"
                          />
                        </div>
                        <div className="navbar-center--body">
                          <img src={entity.fieldMenuIcone.entity.fieldMediaImage.url} className="navbar-center--icon" alt="" />
                          <>
                            <h2>{entity.fieldAccrocheTitre?.processed}</h2>
                            <p>{entity.fieldAccrocheSoustitre?.processed}</p>
                            <div
                              role="presentation"
                              onClick={() => {
                                setShowNavigationMenu(false);
                              }}
                            >
                              <CaliceoLink
                                classNames={`${!entity.fieldAccrocheLien?.title ? 'navbar-center--link' : ''}`}
                                primaryButton
                                showIcon={!entity.fieldAccrocheLien?.title}
                                title={entity.fieldAccrocheLien?.title}
                                url={entity.fieldAccrocheLien?.url.path}
                              />
                            </div>
                          </>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-4">
                <div className="navbar--our-offers--second-level">
                  <h1 className="navbar--offer-header">
                    {selectedCenter?.name !== 'home-groupe' && 'Calicéo'}
                    <br />
                    {`${selectedCenter?.name === 'home-groupe' ? 'À propos de Calicéo' : selectedCenter?.name}`}
                  </h1>
                  {mainMenuContent?.fieldMenuLien && (
                    <div className="navbar--offer-links">
                      {mainMenuContent.fieldMenuLien.map((link) => (
                        <div
                          key={link.title}
                          role="presentation"
                          onClick={() => {
                            setShowNavigationMenu(false);
                          }}
                        >
                          <Link to={link.url.path}>{link.title}</Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="navbar-social--links">
                  {mainMenuContent?.fieldFaqLien && (
                    <div
                      role="presentation"
                      onClick={() => {
                        setShowNavigationMenu(false);
                      }}
                    >
                      <Link className="faq-link" to={mainMenuContent.fieldFaqLien.url.path}>
                        {mainMenuContent.fieldFaqLien.title}
                        <img src={RightArrowIcon} alt="Faq arrow" />
                      </Link>
                    </div>
                  )}
                  <a className="social-link" href={mainMenuContent?.fieldInstagramLien?.url?.path} target="_blank" rel="noreferrer">
                    <img src={IGWhite} alt="" />
                  </a>
                  <a className="social-link" href={mainMenuContent?.fieldFacebookLien?.url?.path} target="_blank" rel="noreferrer">
                    <img src={FBWhite} alt="" />
                  </a>

                  {mainMenuContent?.fieldLinkedinLien?.url?.path && (
                    <a className="social-link" href={mainMenuContent?.fieldLinkedinLien?.url?.path} target="_blank" rel="noreferrer">
                      <img src={LinkedinWhite} alt="" className="linkedinFooter" />
                    </a>
                  )}

                </div>
              </div>
            </div>
          </a.div>
        </a.div>
      </nav>
    </header>
  );
};

export default DesktopNavbar;
