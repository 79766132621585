import React, {useEffect, useState} from 'react';
import { useSpring, animated as a } from 'react-spring';
import { MoreOutlined, UpOutlined, MenuOutlined } from '@ant-design/icons';
import { Link } from 'gatsby';

import OurUniverseIcon from 'images/universe-mobile.svg';
import OurConceptIcon from 'images/concept-mobile.svg';
import FAQIcon from 'images/faq-mobile.svg';
import OurBenefitsIcon from 'images/beneficies-mobile.svg';
import OurOffersIcon from 'images/nos-offres-mobile.svg';
import CartIcon from 'images/mon-panier-mobile.svg';
import AccountIcon from 'images/my-account-mobile.svg';
import EventsIcon from 'images/events-mobile.svg';
import InfoIcon from 'images/info-mobile.svg';
import LocalisateurIcon from 'images/localisateur-de-carte.svg';

const MobileNavbar = (props) => {
  const {
    showNavigationMenu,
    setShowNavigationMenu,
    mainMenuContent,
    selectedCenter,
    cartData
  } = props;

  const [nbProduct, setNbProduct] = useState(0);

  useEffect(() => {
    var nb = 0;
    for(var i=0;i<cartData.Products.length;i++) {
      nb+=cartData.Products[i].quantity;
    }
    setNbProduct(nb);
  }, [cartData]);

  const expandedNavStyle = useSpring({
    height: showNavigationMenu ? '28vh' : '0vh',
    opacity: showNavigationMenu ? 1 : 0,
    config: { mass: 1, tension: 400, friction: 30 },
    zIndex: showNavigationMenu ? 100 : -100,
    padding: showNavigationMenu ? '10px' : '0px',
  });

  if (!mainMenuContent) return null;

  return (
    <div className="mobile-navbar">
      <div className="mobile-navbar-items">
        {selectedCenter?.name === 'home-groupe' ? (
          <>
            <Link to="/pages/groupe-nos-centres" className="mobile-navbar-item">
              <img src={LocalisateurIcon} alt="Our concept" />
              Nos centres
            </Link>
            <Link to={mainMenuContent.fieldMenuMea[2].entity.fieldAccrocheLien.url.path} className="mobile-navbar-item">
              <img src={OurUniverseIcon} alt="Nos univers" />
              Nos univers
            </Link>
            <Link to="/panier" className="mobile-navbar-item">
              <img src={CartIcon} alt="Mon panier" />
              Mon panier
            </Link>
            <Link to="/mon-compte" className="mobile-navbar-item">
              <img src={AccountIcon} alt="Mon espace" />
              Mon espace
            </Link>
          </>
        ) : (
          <>
            <Link to="/" className="mobile-navbar-item">
              <img src={LocalisateurIcon} alt="Le concept" />
              Le centre
            </Link>
            <Link to="/offers" className="mobile-navbar-item">
              <img src={OurOffersIcon} alt="La boutique" />
              La boutique
            </Link>
            <Link to="/panier" className="mobile-navbar-item">
              {cartData?.Products.length > 0 ? (
                  <div className="nb_panier_mob">{nbProduct}</div>
                ) : null }
              <img src={CartIcon} alt="Mon panier" />
              Mon panier
            </Link>
            <Link to="/mon-compte" className="mobile-navbar-item">
              <img src={AccountIcon} alt="Mon espace" />
              Mon espace
            </Link>
            {showNavigationMenu ? (
              <UpOutlined
                style={{ zIndex: 120 }}
                onClick={() => setShowNavigationMenu((prevNavState) => !prevNavState)}
              />
            ) : (
              <MenuOutlined 
                style={{ zIndex: 120 }}
                onClick={() => setShowNavigationMenu((prevNavState) => !prevNavState)}
              />
            )}
          </>
        )}
      </div>
      <a.div className="mobile-expanded-menu" style={expandedNavStyle}>
        {mainMenuContent?.fieldMenuLien.map((menuItem) => {
          if (menuItem?.title === 'Les événements' || menuItem?.title === ' Les événements' || menuItem?.title === 'Les événements ' || menuItem?.title === "Les évènements") {
            return (
              <Link className="mobile-expanded-menu-item" key={menuItem?.url.path} to={menuItem?.url.path}>
                <div className="mobile-expanded-menu-image">
                  <img src={EventsIcon} alt="Événements" />
                </div>
                Événements
              </Link>
            );
          }
          if (menuItem?.title === 'Les informations pratiques' || menuItem?.title === "Informations pratiques") {
            return (
              <Link className="mobile-expanded-menu-item" key={menuItem?.url.path} to={menuItem?.url.path}>
                <div className="mobile-expanded-menu-image">
                  <img src={InfoIcon} alt="Informations pratiques" />
                </div>
                Informations pratiques
              </Link>
            );
          }
          return null;
        })}
        <Link className="mobile-expanded-menu-item" to={mainMenuContent.fieldFaqLien?.url.path}>
          <div className="mobile-expanded-menu-image">
            <img src={FAQIcon} alt="FAQ" />
          </div>
          F.A.Q.
        </Link>
      </a.div>
    </div>
  );
};

export default MobileNavbar;
