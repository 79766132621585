import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectCenters } from 'redux/selectors/centers';
import { logoutUser } from 'redux/actions/auth';
import { selectCartData} from 'redux/selectors/cart';

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
  cartData: selectCartData(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    logoutUser,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
