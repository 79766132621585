import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import RightArrowIcon from 'components/icon-components/RightArrow';

const CaliceoLink = (props) => {
  const {
    primaryButton,
    classNames,
    url,
    title,
    iconFill,
    showIcon,
  } = props;

  return (
    <Link className={`${primaryButton ? 'primary-caliceo--button' : ''} ${classNames} ${title.length > 1 ? '' : 'no-title-btn'}`} to={url}>
      {title}
      {showIcon && (
        <RightArrowIcon fill={iconFill} />
      )}
    </Link>
  );
};

const { string, bool } = PropTypes;

CaliceoLink.defaultProps = {
  url: '',
  title: '',
  iconFill: '#ffffff',
  classNames: '',
  showIcon: true,
};

CaliceoLink.propTypes = {
  url: string,
  title: string,
  iconFill: string,
  classNames: string,
  showIcon: bool,
};

export default CaliceoLink;
