import React from 'react';

const BonCadeau = (props) => (
  <svg width="41px" height="28px" viewBox="0 0 41 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icones/BonCadeau</title>
    <desc>Created with Sketch.</desc>
    <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="MenuPrincipal" transform="translate(-622.000000, -20.000000)">
        <g id="Icones/BonCadeau" transform="translate(624.500000, 20.000000)">
          <g id="food" transform="translate(-2.000000, 0.000000)">
            <g id="Group" transform="translate(19.000000, 0.000000)" fill={props.fill} fillRule="nonzero">
              <path d="M0.449,15.8282667 C0.218833333,15.8282667 0.0323333333,15.6293333 0.0323333333,15.3838222 L0.0323333333,14.3171556 C0.0323333333,14.0716444 0.218833333,13.8727111 0.449,13.8727111 C0.679166667,13.8727111 0.865666667,14.0716444 0.865666667,14.3171556 L0.865666667,15.3838222 C0.865666667,15.6293333 0.679166667,15.8282667 0.449,15.8282667 Z" id="Shape" />
              <path d="M0.449,12.9568 C0.218833333,12.9568 0.0323333333,12.7578667 0.0323333333,12.5123556 L0.0323333333,10.7077333 C0.0323333333,10.4622222 0.218833333,10.2632889 0.449,10.2632889 C0.679166667,10.2632889 0.865666667,10.4622222 0.865666667,10.7077333 L0.865666667,12.5123556 C0.865666667,12.7578667 0.679166667,12.9568 0.449,12.9568 Z M0.449,9.34755556 C0.218833333,9.34755556 0.0323333333,9.14862222 0.0323333333,8.90311111 L0.0323333333,7.09831111 C0.0323333333,6.8528 0.218833333,6.65386667 0.449,6.65386667 C0.679166667,6.65386667 0.865666667,6.8528 0.865666667,7.09831111 L0.865666667,8.90311111 C0.865666667,9.14844444 0.679166667,9.34755556 0.449,9.34755556 Z M0.449,5.73813333 C0.218833333,5.73813333 0.0323333333,5.5392 0.0323333333,5.29368889 L0.0323333333,3.48888889 C0.0323333333,3.24337778 0.218833333,3.04444444 0.449,3.04444444 C0.679166667,3.04444444 0.865666667,3.24337778 0.865666667,3.48888889 L0.865666667,5.29351111 C0.865666667,5.5392 0.679166667,5.73813333 0.449,5.73813333 Z" id="Shape" />
              <path d="M0.449,2.12871111 C0.218833333,2.12871111 0.0323333333,1.92977778 0.0323333333,1.68426667 L0.0323333333,0.6176 C0.0323333333,0.372088889 0.218833333,0.173155556 0.449,0.173155556 C0.679166667,0.173155556 0.865666667,0.372088889 0.865666667,0.6176 L0.865666667,1.68426667 C0.865666667,1.92977778 0.679166667,2.12871111 0.449,2.12871111 Z" id="Shape" />
            </g>
            <g id="Group" fill={props.fill} fillRule="nonzero">
              <path d="M39.4409174,16.76395 C39.1891743,16.725975 38.9557798,16.88925 38.9163303,17.127775 L38.1411009,21.80815 C38.1411009,21.80815 0.719082569,16.504075 0.698348624,16.5011 C0.161284404,16.424975 -0.0297247706,17.2032 0.497431193,17.355975 C0.497431193,17.355975 36.5289908,27.8075 36.5390826,27.810475 C36.7748624,27.8789 37.0387156,27.747125 37.1113761,27.519275 L38.1379817,24.299975 C38.2117431,24.0688 38.0750459,23.824325 37.8326606,23.75415 C37.5900917,23.6838 37.333945,23.814175 37.260367,24.04535 L36.367156,26.84605 L6.67027523,18.2322 L38.4557798,22.7374 C38.4787156,22.740725 38.5014679,22.7423 38.5238532,22.7423 C38.7458716,22.7423 38.9407339,22.5883 38.9763303,22.37305 L39.8223853,17.264625 C39.8618349,17.02575 39.6910092,16.80175 39.4409174,16.76395 Z" id="Shape" />
              <path d="M0.630642202,15.58095 L38.5231193,15.58095 C38.7765138,15.58095 38.9818349,15.385125 38.9818349,15.14345 L38.9818349,0.60795 C38.9818349,0.366275 38.7765138,0.17045 38.5231193,0.17045 L0.630642202,0.17045 C0.377247706,0.17045 0.171926606,0.366275 0.171926606,0.60795 L0.171926606,15.14345 C0.171926606,15.385125 0.377431193,15.58095 0.630642202,15.58095 Z M1.0893578,1.04545 L38.0644037,1.04545 L38.0644037,14.70595 L1.0893578,14.70595 L1.0893578,1.04545 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BonCadeau;
