import React from 'react';
import PropTypes from 'prop-types';

const RightArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14">
    <path fill={props.fill} d="M20.872 6.69L14.309.129c-.17-.17-.447-.17-.618 0-.171.171-.171.448 0 .619l5.815 5.816H.438C.196 6.563 0 6.758 0 7s.196.438.438.438h19.068l-5.815 5.815c-.171.171-.171.448 0 .619.085.085.197.128.309.128.112 0 .224-.043.31-.128l6.562-6.563c.17-.17.17-.447 0-.618z" />
  </svg>
);

RightArrow.defaultProps = {
  fill: '#ffffff',
};

RightArrow.propTypes = {
  fill: PropTypes.string,
}

export default RightArrow;
