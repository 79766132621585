import Cookies from 'universal-cookie';
import { centerCookieIds } from 'utils/constants';

const cookies = new Cookies();

const userIsLoggedIn = (selectedCenter) => localStorage.getItem(
  centerCookieIds[selectedCenter]?.cookieName,
) === cookies.get(centerCookieIds[selectedCenter]?.cookieName);

export default userIsLoggedIn;
