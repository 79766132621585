import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setCenters } from 'redux/actions/centers';
import { selectCentersExist } from 'redux/selectors/centers';

const mapStateToProps = (state) => ({
  centersFetched: selectCentersExist(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setCenters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
