import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import useGetCenters from 'hooks/use-get-centers';
import Navbar from '../Navbar';
import Footer from '../footer/Footer';
import './layout.scss';
import SEO from 'components/seo';

import gql from 'graphql-tag';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getCenterById } from 'utils/getCentersBy';
const Layout = (props) => {
  const {
    actions,
    centersFetched,
    selectedCenter
  } = props;
  const centers = useGetCenters();

  useEffect(() => {
    if (!centersFetched) {
      actions.setCenters(centers);
    }
  }, []);
  
  
const centerObject = getCenterById(centers, selectedCenter)



//console.log(centerObject)
//console.log(selectedCenter)
//console.log(centers)

  const siteData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const layoutChildren = React.Children.map(props.children,
    (child) => React.cloneElement(child, { location: props.location }));
    

  return (
    <>
      <Navbar
        location={props.location}
        landingPage={props.landingPage}
        siteTitle={siteData.site.siteMetadata.title}
      />
      {centerObject && (<SEO title={centerObject.meta.entity?.fieldMetatagTitre} description={centerObject.meta.entity?.fieldMetatagDescription} />)}
      
      <div>
        <div className="header-spacer" />
        <main className="main-layout">
          {layoutChildren}
        </main>
        <Footer location={props.location} />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
