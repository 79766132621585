import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { centerCookieIds } from 'utils/constants';

function SEO({
  description, lang, meta, title, center
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  var googleVerification = "HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw";
  if (center != "caliceo") {
    googleVerification = "HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw";
  }
  const [hotjarLyon, setHotjarLyon] = useState("");

  var url_canonical = "";


  if(typeof window !== `undefined`) {
    url_canonical = location.origin + location.pathname;
  }

  if (typeof document !== 'undefined') {

    var loaded = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=AW-10838964761"]') ;
    //var loaded2 = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=AW-10838964761"]') ;


    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10838964761';

    var script_tag = document.createElement('script');
    script_tag.type = 'text/javascript';
    script_tag.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-10838964761');";


    var loaded_groupe = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-KMTK4NPFC4"]') ;
    var loaded_bordeaux = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-C629V2W4FH"]') ;
    var loaded_lyon = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-5DS45Z6EKH"]') ;
    var loaded_nantes = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-CQGXBCCCVL"]') ;
    var loaded_pau = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-F20SGY9PXT"]') ;
    var loaded_perpignan = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-S0YRN0R0ES"]') ;
    var loaded_toulouse = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-NHR4HZVWDW"]') ;

    var script_GA4_groupe = document.createElement('script');
    script_GA4_groupe.type = 'text/javascript';
    script_GA4_groupe.src = 'https://www.googletagmanager.com/gtag/js?id=G-KMTK4NPFC4';

    var script_tag_GA4_groupe = document.createElement('script');
    script_tag_GA4_groupe.type = 'text/javascript';
    script_tag_GA4_groupe.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-KMTK4NPFC4');";

    if(!location.hostname.includes("nantes") && !location.hostname.includes("bordeaux") && !location.hostname.includes("lyon") && !location.hostname.includes("pau") && !location.hostname.includes("perpignan") && !location.hostname.includes("toulouse")) {
      if(loaded_groupe == null) {
        document.head.appendChild(script_GA4_groupe);
        document.head.appendChild(script_tag_GA4_groupe);
      }
    }

    var script_GA4_bordeaux = document.createElement('script');
    script_GA4_bordeaux.type = 'text/javascript';
    script_GA4_bordeaux.src = 'https://www.googletagmanager.com/gtag/js?id=G-C629V2W4FH';

    var script_tag_GA4_bordeaux = document.createElement('script');
    script_tag_GA4_bordeaux.type = 'text/javascript';
    script_tag_GA4_bordeaux.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-C629V2W4FH');";

    if(location.hostname.includes("bordeaux")) {
      if(loaded_bordeaux == null) {
        document.head.appendChild(script_GA4_bordeaux);
        document.head.appendChild(script_tag_GA4_bordeaux);
      }
      
    }

    var script_GA4_lyon = document.createElement('script');
    script_GA4_lyon.type = 'text/javascript';
    script_GA4_lyon.src = 'https://www.googletagmanager.com/gtag/js?id=G-5DS45Z6EKH';

    var script_tag_GA4_lyon = document.createElement('script');
    script_tag_GA4_lyon.type = 'text/javascript';
    script_tag_GA4_lyon.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-5DS45Z6EKH');";


    if(location.hostname.includes("lyon")) {
      if(loaded_lyon == null) {
        document.head.appendChild(script_GA4_lyon);
        document.head.appendChild(script_tag_GA4_lyon);
      }
      
    }

    var script_GA4_nantes = document.createElement('script');
    script_GA4_nantes.type = 'text/javascript';
    script_GA4_nantes.src = 'https://www.googletagmanager.com/gtag/js?id=G-CQGXBCCCVL';

    var script_tag_GA4_nantes = document.createElement('script');
    script_tag_GA4_nantes.type = 'text/javascript';
    script_tag_GA4_nantes.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-CQGXBCCCVL');";


    var script_GA4_pau = document.createElement('script');
    script_GA4_pau.type = 'text/javascript';
    script_GA4_pau.src = 'https://www.googletagmanager.com/gtag/js?id=G-F20SGY9PXT';

    var script_tag_GA4_pau = document.createElement('script');
    script_tag_GA4_pau.type = 'text/javascript';
    script_tag_GA4_pau.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-F20SGY9PXT');";

    if(location.hostname.includes("pau")) {
      if(loaded_pau == null) {
        document.head.appendChild(script_GA4_pau);
        document.head.appendChild(script_tag_GA4_pau);
      }
      
    }

    var script_GA4_perpignan = document.createElement('script');
    script_GA4_perpignan.type = 'text/javascript';
    script_GA4_perpignan.src = 'https://www.googletagmanager.com/gtag/js?id=G-S0YRN0R0ES';

    var script_tag_GA4_perpignan = document.createElement('script');
    script_tag_GA4_perpignan.type = 'text/javascript';
    script_tag_GA4_perpignan.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-S0YRN0R0ES');";

    if(location.hostname.includes("perpignan")) {
      if(loaded_perpignan == null) {
        document.head.appendChild(script_GA4_perpignan);
        document.head.appendChild(script_tag_GA4_perpignan);
      }
      
    }

    var script_GA4_toulouse = document.createElement('script');
    script_GA4_toulouse.type = 'text/javascript';
    script_GA4_toulouse.src = 'https://www.googletagmanager.com/gtag/js?id=G-NHR4HZVWDW';

    var script_tag_GA4_toulouse = document.createElement('script');
    script_tag_GA4_toulouse.type = 'text/javascript';
    script_tag_GA4_toulouse.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-NHR4HZVWDW');";

    if(location.hostname.includes("toulouse")) {
      if(loaded_toulouse == null) {
        document.head.appendChild(script_GA4_toulouse);
        document.head.appendChild(script_tag_GA4_toulouse);
      }
      
    }
    
    

    if(location.hostname.includes("nantes")) {
      if(loaded == null) {
        document.head.appendChild(script);
        document.head.appendChild(script_tag);
        document.head.appendChild(script_GA4_nantes);
        document.head.appendChild(script_tag_GA4_nantes);
      }
    }

    
  }

  useEffect(() => {
    if(location.hostname.includes("lyon")) {
      if(hotjarLyon === "") {
        setHotjarLyon("(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3194485,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');");
      }
      
    }
  }, []);

  return (
    <Helmet
      titleAttributes={{ itemprop: "name" }}
      htmlAttributes={{
        lang,
        itemscope: "",
        itemtype: "http://schema.org/WebPage",
      }}
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      script={[{ 
        type: 'text/javascript', 
        innerHTML: "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '221931016717427');fbq('track', 'PageView');"
      },{
        type: 'text/javascript', 
        innerHTML: hotjarLyon
      },{
        type: 'text/javascript', 
        src: "https://www.googletagmanager.com/gtag/js?id=G-XK0KW9L3ZC"
      },{
        type: 'text/javascript', 
        innerHTML: "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-XK0KW9L3ZC');"
      }]}
      link={
        url_canonical
          ? [
              {
                rel: "canonical",
                href: url_canonical,
              },
            ]
          : []
      }
    >
      <meta name="theme-color" content="#8BACDB" />
      <meta name="facebook-domain-verification" content="36hcd7lka5yolmnen6bjpx9lrxy8pf" />


      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="google-site-verification" content={googleVerification} />

      
    <noscript>{`
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=221931016717427&ev=PageView&noscript=1" />
    `}</noscript>
    </Helmet>    

  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
