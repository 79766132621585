import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import useScrollHandler from 'hooks/use-scroll-handler';
import { getCenterById } from 'utils/getCentersBy';

import { getCenterName } from 'utils/location';
import useWindowWidth from 'hooks/use-window-width';

import MobileNavbar from './MobileNavbar';
import DesktopNavbar from './DesktopNavbar';

import NAVBAR_QUERY from './hooks';

import './header.scss';

const Header = (props) => {
  const {
    location, centers, actions, cartData,
  } = props;
  const centerName = getCenterName(location);
  const isScrollOnTop = useScrollHandler();
  const [showNavigationMenu, setShowNavigationMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  const [selectedCenter, setSelectedCenter] = useState(null);

  useEffect(() => {
    const centerById = getCenterById(centers, `centre-${centerName}`);
    setSelectedCenter(centerById || { domainAccess: 'caliceo_epfactory_com', name: 'home-groupe' });
  }, [centerName, centers]);

  const [getNavbar, navbarRes] = useLazyQuery(NAVBAR_QUERY);

  useEffect(() => {
    if (selectedCenter) {
      getNavbar({
        variables: {
          navbarConditions: [
            { field: 'type', value: 'menu' },
            { field: 'field_relation_domain', value: `${selectedCenter?.domainAccess}` },
          ],
        },
      });
    }
  }, [selectedCenter]);

  const mainMenuContent = navbarRes?.data?.blockContentQuery?.entities[0]?.fieldMenu.entity;

  const determineIconFill = () => {
    if (showNavigationMenu && isScrollOnTop) {
      return '#FFFFFF';
    }
    if (!showNavigationMenu && !isScrollOnTop) {
      return '#8bacdb';
    }
    if (showNavigationMenu && !isScrollOnTop) {
      return '#FFFFFF';
    }
    if (!showNavigationMenu && isScrollOnTop) {
      return '#FFFFFF';
    }
    return '#8bacdb';
  };

  if (windowWidth < 768) {
    return (
      <MobileNavbar
        isScrollOnTop={isScrollOnTop}
        determineIconFill={determineIconFill}
        showNavigationMenu={showNavigationMenu}
        setShowNavigationMenu={setShowNavigationMenu}
        mainMenuContent={mainMenuContent}
        selectedCenter={selectedCenter}
        logout={actions.logoutUser}
        cartData={cartData}
      />
    );
  }

  return (
    <DesktopNavbar
      isScrollOnTop={isScrollOnTop}
      determineIconFill={determineIconFill}
      showNavigationMenu={showNavigationMenu}
      setShowNavigationMenu={setShowNavigationMenu}
      mainMenuContent={mainMenuContent}
      selectedCenter={selectedCenter}
      logout={actions.logoutUser}
      cartData={cartData}
    />
  );
};

export default Header;
