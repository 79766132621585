import gql from 'graphql-tag';

export const FOOTER_SITEMAP_QUERY = gql`
  query ($menuName: String!){
    menuByName(name: $menuName) {
      links {
        ...on MenuLink {
          url {
            path
          }
        }
        label
        url {
          path
        }
        expanded
      }
    }
  }
`;

export const FOOTER_EXTRAS_QUERY = gql`
  query ($menuName: String!){
    menuByName(name: $menuName) {
      links {
        ...on MenuLink {
          url {
            path
          }
        }
        label
        url {
          path
        }
        expanded
      }
    }
  }
`;


export const FOOTER_RS = gql`
  query NavbarQuery($navbarConditions: [EntityQueryFilterConditionInput]) {
    blockContentQuery(filter: { conditions: $navbarConditions }) {
      entities {
        ... on BlockContentMenu {
          fieldMenu {
            entity {
              ... on ParagraphMenuDeNavigation {
                fieldMenuMea {
                  entity {
                    ... on ParagraphMenuPageEnAvant {
                      fieldAccrocheTitre {
                        processed
                      }
                      fieldAccrocheSoustitre {
                        processed
                      }
                      fieldAccrocheImage {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              derivative(style: LARGE) {
                                url
                              }
                            }
                          }
                        }
                      }
                      fieldAccrocheLien {
                        url {
                          path
                        }
                        uri
                        title
                      }
                      fieldMenuIcone {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldMenuLien {
                  title
                  url {
                    path
                  }
                }
                fieldFaqLien {
                  title
                  url {
                    path
                  }
                  uri
                }
                fieldFacebookLien {
                  title
                  url {
                    path
                  }
                  uri
                }
                fieldInstagramLien {
                  title
                  url {
                    path
                  }
                  uri
                }
                fieldLinkedinLien {
                  title
                  url {
                    path
                  }
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`;
