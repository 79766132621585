import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Input, notification } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import CaliceoLogo from 'images/caliceo-menu-logo.svg';

import CaliceoLogo2 from 'images/icon_pwa.png';

import IGWhite from 'images/icons/RS/Instagram.svg';
import FBWhite from 'images/icons/RS/FB.svg';
import LinkedinWhite from 'images/icons/RS/linkedin.svg';
import RightArrow from 'images/icons/right-arrow.svg';

import { getCenterName } from 'utils/location';

import './footer.scss';
import CaliceoButton from 'components/buttons/CaliceoButton';
import API from 'api';
import qs from 'qs';
import { FOOTER_SITEMAP_QUERY, FOOTER_EXTRAS_QUERY, FOOTER_RS } from './hooks';

const Footer = (props) => {
  const { location } = props;
  const [menuName, setMenuName] = useState('groupe');
  const [getFooterUpperLinks, footerUpperLinksRes] = useLazyQuery(FOOTER_SITEMAP_QUERY);
  const [getFooterLowerLinks, footerLowerLinksRes] = useLazyQuery(FOOTER_EXTRAS_QUERY);
  const [getFooterRS, footerRSRes] = useLazyQuery(FOOTER_RS);

  const [messageSend, setMessageSend] = useState(false);
  const getDomainName = (url) => {
    const centerName = getCenterName(url);
    if (centerName !== 'caliceo') {
      if (centerName === 'saintcyrlecole') {
        setMenuName('--saint-cyr');
      } else {
        setMenuName(centerName);
      }
    } else {
      setMenuName('groupe');
    }
  };

  const handleSubmitNewsletterForm = (e) => {
    e.preventDefault();
    const formValue = document.getElementById('footer-newsletter--input').value;
    let formIsValid = true;

    const valuesToSubmit = {
      email: formValue,
      fid: 'newsletter',
      centre:window.location.hostname.split('.')[0],
    };

    const formData = new FormData();
    formData.append('data', qs.stringify(valuesToSubmit));

    if (!formValue) {
      formIsValid = false;
    }
    if (typeof formValue !== 'undefined') {
      const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
      if (!pattern.test(formValue)) {
        formIsValid = false;
      }
    } else {
      formIsValid = false;
    }

    if (formIsValid) {
      API.post('/pwd', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
        if (res.data.status === 1) {
          setMessageSend(true);
          notification.success({
            message: 'Votre inscription a bien été enregistrée.',
          });
        } else if (res.data.status > 1) {
          if (res.data.errorMessage != '' && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      });
    } else {
      notification.error({
        message: 'Adresse mail incorrecte',
      });
    }
  };

  useEffect(() => {
    getDomainName(location);

    getFooterRS({
      variables: {
        navbarConditions: [
          { field: 'type', value: 'menu' },
          { field: 'field_relation_domain', value: 'caliceo_epfactory_com' },
        ],
      },
    });
  }, [location]);

  useEffect(() => {
    if (menuName) {
      getFooterUpperLinks({
        variables: {
          menuName: `footer-sitemap-${menuName}`,
        },
      });
      getFooterLowerLinks({
        variables: {
          menuName: `footer-extras-${menuName}`,
        },
      });
    }
  }, [menuName]);

  return (
    <footer className="site-footer">
      <div className="container footer-container">
        <div className="footer--upper-row">
          <Link to="/" className="footer-brand">
            <img src={CaliceoLogo} className="footer-brand-image" alt="" />
          </Link>
          {footerUpperLinksRes.data && footerUpperLinksRes.data.menuByName && (
            <div className="footer-upper--links">
              {footerUpperLinksRes.data?.menuByName.links.map((link) => {
                // console.log(footerUpperLinksRes.data);
                const classN = '';
                if (link?.url?.path.includes('http')) {
                  return (
                    <a
                      href={link.url.path}
                      className="footer-upper-link"
                      target="_blank"
                      key={link.url.path}
                      rel="noreferrer"
                    >
                      {link.label}
                    </a>
                  );
                }
                if (link.label === 'SEP') {
                  return (
                    <div className="sep" />
                  );
                }
                return (
                  <Link className="footer-upper-link " key={link.url.path} to={`${link.url.path}`}>
                    {link.label}
                  </Link>
                );
              })}
            </div>
          )}
          <div className="footer-social--links">
            <a className="social-link" href={footerRSRes?.data?.blockContentQuery?.entities[0]?.fieldMenu?.entity?.fieldInstagramLien?.url?.path} target="_blank" rel="noreferrer">
              <img src={IGWhite} alt="" />
            </a>
            <a className="social-link" href={footerRSRes?.data?.blockContentQuery?.entities[0]?.fieldMenu?.entity?.fieldFacebookLien?.url?.path} target="_blank" rel="noreferrer">
              <img src={FBWhite} alt="" />
            </a>
            {footerRSRes?.data?.blockContentQuery?.entities[0]?.fieldMenu?.entity?.fieldLinkedinLien?.url?.path && (
              <a className="social-link" href={footerRSRes?.data?.blockContentQuery?.entities[0]?.fieldMenu?.entity?.fieldLinkedinLien?.url?.path} target="_blank" rel="noreferrer">
                <img src={LinkedinWhite} alt="" className="linkedinFooter" />
              </a>
            )}

          </div>
        </div>
        <div className="footer--lower-row">
          {footerLowerLinksRes.data && footerLowerLinksRes.data.menuByName && (
            <div className="footer--lower-links">
              {footerLowerLinksRes.data?.menuByName.links.map((link) => (
                <React.Fragment key={link.url.path}>
                  <span className="footer-lower-link">-</span>
                  {(link.label === 'Cookies')
                    ? <span className="footer-lower-link cookies-tarteaucitron" dangerouslySetInnerHTML={{ __html: ' <span onclick="tarteaucitron.userInterface.openPanel();">Cookies</span>' }} />
                    : (
                      <Link className="footer-lower-link" to={`${link.url.path}`}>
                        {link.label}
                      </Link>
                    )}
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="footer-newsletter">
            {menuName !== 'groupe' && (
              <>
                <span className="newsletter-label">abonnez-vous à notre newsletter</span>
                <Input
                  className="footer-newsletter--input"
                  id="footer-newsletter--input"
                  placeholder="Email"
                  addonAfter={(
                    <>
                      <button
                        type="button"
                        className="newsletter--button"
                        onClick={handleSubmitNewsletterForm}
                      >
                        <img src={RightArrow} alt="Newsletter arrow" />
                      </button>
                    </>
                    )}
                />
              </>
            )}

          </div>
        </div>
      </div>
      <div className="footer-bottom">TOUS DROITS RÉSERVÉS - © CALICEO 2022</div>

    </footer>
  );
};

export default Footer;
