const forwardToCenter = (centers, center = 'centre-caliceo', hostname, url = '/') => {
  const cleanCenter = center.split('-')[1];
  const centerNameToCheck = hostname.split('.')[0];

  const isValidCenter = centers.some((_center) => _center.centerId.includes(centerNameToCheck));

  const getNewUrl = isValidCenter ? `${hostname.substr(hostname.indexOf('.') + 1)}` : hostname;
  if (typeof window !== 'undefined') {
    window.location.href = `https://${cleanCenter}.${hostname.includes('localhost') ? 'localhost:8000' : getNewUrl}${url}`;
  }
};

export default forwardToCenter;
