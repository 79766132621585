import React from 'react';

const Cart = (props) => (
  <svg width="28px" height="25px" viewBox="0 0 28 25">
    <defs>
      <path id="prefix__a" d="M0 25h28V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M18.557 21.988a.516.516 0 00.11.012c.32 0 .603-.303.657-.731l.667-5.25c.06-.477-.185-.927-.548-1.007-.363-.08-.707.243-.767.72l-.667 5.249c-.06.477.185.928.548 1.007M8.676 21.269c.054.428.336.731.656.731a.513.513 0 00.11-.012c.364-.08.61-.53.549-1.007l-.667-5.25c-.06-.476-.404-.798-.767-.719-.363.08-.608.53-.548 1.007l.667 5.25z"
        fill={props.fill}
      />
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        d="M1.64 11.667h24.72V10H1.64v1.667zm20 11.666H6.36l-2.812-10h20.904l-2.813 10zm5.54-15h-2.887L17.976.313a.812.812 0 00-1.152-.13.842.842 0 00-.129 1.17l5.497 6.98H5.808l5.497-6.98a.842.842 0 00-.128-1.17.812.812 0 00-1.153.13l-6.317 8.02H.82a.827.827 0 00-.82.834V12.5c0 .46.367.833.82.833h1.022l3.111 11.063c.101.357.423.604.79.604h16.515a.822.822 0 00.789-.604l3.11-11.063h1.023c.453 0 .82-.373.82-.833V9.167a.827.827 0 00-.82-.834z"
        fill={props.fill}
        fillRule="nonzero"
        mask="url(#prefix__b)"
      />
      <path
        d="M14.5 22c.276 0 .5-.392.5-.875v-5.25c0-.483-.224-.875-.5-.875s-.5.392-.5.875v5.25c0 .483.224.875.5.875"
        fill={props.fill}
        mask="url(#prefix__b)"
      />
    </g>
  </svg>
);

export default Cart;
