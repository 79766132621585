import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

import RightArrowIcon from 'components/icon-components/RightArrow';

const CaliceoButton = (props) => {
  const {
    primaryButton,
    classNames,
    onClick,
    title,
    iconFill,
    showIcon,
    loading,
    disabled,
    type,
  } = props;

  return (
    <button
      type={type}
      className={`${primaryButton ? 'primary-caliceo--button' : ''} ${classNames}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? (
        <LoadingOutlined className="button-loading-icon" />
      ) : (
        <>
          {title}
          {showIcon && <RightArrowIcon fill={iconFill} />}
        </>
      )}
    </button>
  );
};

const { string, func, bool } = PropTypes;

CaliceoButton.defaultProps = {
  title: '',
  iconFill: '#ffffff',
  classNames: '',
  showIcon: true,
  loading: false,
  disabled: false,
  type: 'button',
  onClick: () => {},
};

CaliceoButton.propTypes = {
  onClick: func,
  title: string,
  iconFill: string,
  classNames: string,
  showIcon: bool,
  loading: bool,
  disabled: bool,
  type: string,
};

export default CaliceoButton;
